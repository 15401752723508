import axios from 'axios';
const instance = axios.create({
    baseURL: process.env.REACT_APP_LAMBDA_URL,
    timeout: 10000,
  });

const exportedObject = {
    async fetchTvShow(uuid, tvShowName) {
        // TODO: Error handle
        let episodeResponse = await instance.get('/omdb-request', {
            params: {
                tvShowName: tvShowName,
                uuid: uuid,
            }
        });
        return episodeResponse.data;
    }
}
export default exportedObject;
