const reducer = (accumulator, currentValue) => accumulator + currentValue;

/**
 * Calculate the trendline from data points.
 * 
 * I strongly dislike how I'm checking explicitly for `episodeNumber` and `rating` in this class. I think this should have as
 * little knowledge about the data structure as possible.
 * 
 * @param {Dictionary} data Data points
 */
export default function calculateTrend(data) {
    const filteredData = data.filter(item => item.rating !== 0)

    if (filteredData.length !== 0) {
        const a = calculateGradient(filteredData);
        const b = calculateIntercept(filteredData, a);

        const trendStartCoord = calculateStartOfTrend(filteredData, a, b);
        const trendFinalCoord = calculateEndOfTrend(filteredData, a, b);
        return {
            firstX: trendStartCoord["x"],
            firstY: trendStartCoord["y"],
            lastX: trendFinalCoord["x"],
            lastY: trendFinalCoord["y"],
        }
    }
}

function calculateGradient(data) {
    const nSigmaxy = data.length * data.map(item => item.episodeNumber * item.rating).reduce(reducer);
    const sigmaXsigmaY = data.map(item => item.episodeNumber).reduce(reducer) * data.map(item => item.rating).reduce(reducer);
    const nSigmaxSquared = data.length * data.map(item => item.episodeNumber).map(item => item * item).reduce(reducer);
    const sigmaXallSquared = data.map(item => item.episodeNumber).reduce(reducer) * data.map(item => item.episodeNumber).reduce(reducer);

    return ((nSigmaxy - sigmaXsigmaY) / (nSigmaxSquared - sigmaXallSquared));
}

function calculateIntercept(data, a) {
    return (data.map(item => item.rating).reduce(reducer) - (a * data.map(item => item.episodeNumber).reduce(reducer))) / data.length
}

function calculateStartOfTrend(data, a, b) {
    const firstX = data[0].episodeNumber;
    const y = (firstX * a) + b;
    return {
      x: firstX,
      y: y,
    };
}

function calculateEndOfTrend(data, a, b) {
    const lastX = data[data.length-1].episodeNumber;
    const y = (lastX * a) + b
    return {
      x: lastX,
      y: y,
    };
}